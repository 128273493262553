<template>

	<!-- Building Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">Building Information</h6>
		</template>
		<!-- <a-button type="link" slot="extra">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
			</svg>
		</a-button> -->
		
		<a-descriptions  :column="1">
			<a-descriptions-item label="Address">
				{{buildingInfo.address}}
			</a-descriptions-item>
			<a-descriptions-item label="Number of Units">
				{{buildingInfo.apartments}}
			</a-descriptions-item>
			<a-descriptions-item label="Board President">
				{{buildingInfo.board_president}}
			</a-descriptions-item>
			<a-descriptions-item label="Secretary">
				{{buildingInfo.secretary}}
			</a-descriptions-item>
			<a-descriptions-item label="Treasurer">
				{{buildingInfo.treasurer}}
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.super_name" label="Superintendent">
				{{buildingInfo.super_name}}
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.super_email" label="Superintendent Email">
				<a v-bind:href="`mailto:${buildingInfo.super_email}`">{{buildingInfo.super_email}}</a> 
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.super_phone" label="Superintendent Phone">
				<a v-bind:href="`tel:${buildingInfo.super_phone}`">{{buildingInfo.super_phone}}</a>
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.tax_id" label="Tax ID">
				{{buildingInfo.tax_id}}
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.management" label="Management Company">
				{{buildingInfo.management}}
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.managing_agent" label="Managing Agent">
				{{buildingInfo.managing_agent}}
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.managing_agent_email" label="Managing Agent Email">
				<a v-bind:href="`mailto:${buildingInfo.managing_agent_email}`">{{buildingInfo.managing_agent_email}}</a> 
			</a-descriptions-item>
			<a-descriptions-item v-if="buildingInfo.managing_agent_phone" label="Managing Agent Phone">
				<a v-bind:href="`tel:${buildingInfo.managing_agent_phone}`">{{buildingInfo.managing_agent_phone}}</a>
			</a-descriptions-item>
		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>

	export default ({
		props: {
			buildingInfo: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
			}
		},
	})

</script>